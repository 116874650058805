import LocalTrainingRepository from '@/shared/http/repositories/socialProject/localtraining'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import fab from 'vue-fab'
import Roles from '@/shared/mixins/hasRoles'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'LocalDeTreinamento',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search,
    fab
  },

  mixins: [Roles],

  data: () => ({
    localTrainings: [],
    labelSearch: 'Locais de Treinamento',
    showModalLocalTraining: false,
    title: 'Novo Local de Treinamento',
    id: null,
    searchText: null,
    fields: [
      {
        key: 'name',
        label: 'LOCAIS DE TREINAMENTO',
        class: 'left-header-border text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Locais de Treinamento',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      LocalTrainingRepository.GetAll(this.searchText, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.localTrainings = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar local de treinamento', 'ERRO')
        })
    },

    search(searchText) {
      this.searchText = searchText
      this.getAll()
    },

    onDelete() {
      loading.push()
      LocalTrainingRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalLocalTraining = false
          toast.success('Local de treinamento excluído com sucesso!', 'EXCLUSÃO DO LOCAL DE TREINAMENTO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir local de treinamento', 'ERRO')
        })
    },

    selectRow(id) {
      this.showModalLocalTraining = true
      this.id = id
    }
  }
}
